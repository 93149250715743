import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/(app)/checkout": [11,[2]],
		"/(app)/discover": [12,[2]],
		"/(app)/(private)/home": [5,[2,3]],
		"/login": [~25],
		"/logout": [~26],
		"/onboarding": [27],
		"/(app)/products": [13,[2]],
		"/(app)/(private)/products/edit": [6,[2,3]],
		"/(app)/products/[slug]": [14,[2]],
		"/(app)/profiles/[slug]": [15,[2]],
		"/(app)/(private)/profile": [7,[2,3]],
		"/(app)/protocols": [16,[2]],
		"/(app)/protocols/[slug]": [17,[2]],
		"/(app)/protocols/[slug]/products": [18,[2]],
		"/(app)/recommendations": [19,[2]],
		"/(app)/(private)/stacks": [8,[2,3]],
		"/(app)/(private)/stacks/[slug]": [9,[2,3]],
		"/(app)/stacks/[slug]/public": [20,[2]],
		"/(app)/subjects": [21,[2]],
		"/(app)/(private)/subjects/edit": [10,[2,3]],
		"/(app)/subjects/[slug]": [22,[2]],
		"/(app)/supplements": [23,[2]],
		"/(app)/supplements/[slug]": [24,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';